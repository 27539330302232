<template>
    <section>
        <GetFile class="mr-3"
                 @get-file="getPDF('pdf')"></GetFile>
        <v-container fluid class="h-70">
            <canvas width="100%" height="100%" ref="content"></canvas>
        </v-container>
    </section>
</template>

<script>
import Chart from 'chart.js';
import GetFile from '@/components/widgets/buttons/GetFile';
import { ICONS } from '@/constants/icons';
import { jsPDF as JSPDF } from 'jspdf';
export default {
    name: 'Chart',
    props: {
        chartType: String,
        chartData: Object,
        chartOptions: Object
    },
    components: { GetFile },
    data: () => ({
        ICONS
    }),
    methods: {
        chartConstructor (chartType, chartData, chartOptions) {
            const chartElement = document.querySelector('canvas');
            const chart = new Chart(chartElement, {
                type: chartType,
                data: chartData,
                options: chartOptions
            });
            return chart;
        },
        getPDF () {
            const doc = new JSPDF('landscape');
            const canvasImg = this.$refs.content.toDataURL('image/png', 1.0);
            doc.addImage(canvasImg, 'JPEG', 10, 10, 280, 150);
            doc.save('canvas.pdf');
        }
    },
    mounted () {
        const { chartType, chartData, chartOptions } = this;
        this.chartConstructor(chartType, chartData, chartOptions);
    }
};
</script>
<style scoped>
    .h-70 {
        height: 70vh;
    }
</style>
