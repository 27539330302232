<template>
    <section>
        <v-container class="pt-5">
            <v-row>
                <v-col cols="12" md="3" class="py-0">
                    <SelectField
                        :label="$t('navigation.incidents_quantity')"
                        @change="reset()"
                        v-model="selectedType"
                        return-object
                        :hide-selected="false"
                        item-text="text"
                        item-value="value"
                        persistent-hint
                        :items="perOptions"></SelectField>
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                    <YearPicker
                        @input="getData()"
                        :label="$t('labels.year')"
                        :max ="`${this.currentYear}-NaN-NaN`"
                        v-model="selectedYear"
                    ></YearPicker>
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                    <Autocomplete :label="$t('labels.customer')"
                                  name="customer_id"
                                  @change="getData()"
                                  @load-more-items="getCustomersLazyLoading(true)"
                                  :items="listingCustomers"
                                  :loading="isCustomersLoading"
                                  :search-input.sync="searchCustomers"
                                  v-model="customerId"></Autocomplete>
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                    <Autocomplete :label="$t('labels.site')"
                                  name="sitesIds"
                                  :items="sites"
                                  @change="getData()"
                                  @focus="getSites(null, null, null)"
                                  @load-more-items="getSitesLazyLoading"
                                  :loading="isSitesLoading"
                                  :search-input.sync="searchSites"
                                  v-model="siteId"></Autocomplete>
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                    <Autocomplete :label="$t('labels.location')"
                                  :items="locations"
                                  name="location"
                                  @change="getData()"
                                  ref="location"
                                  :loading="isLocationsLoading"
                                  :search-input.sync="searchLocations"
                                  @focus="getLocations(null)"
                                  @load-more-items="getLocationsLazyLoading"
                                  v-model="locationId">

                    </Autocomplete>
                </v-col>
                <v-col cols="12" md="1" class="py-0">
                    <v-icon size="24"
                            color="secondary"
                            data-test="resetFilters"
                            class="mt-2 mr-2"
                            @click="reset">{{ ICONS.CLEAN_FILTER }}</v-icon>
                    <v-icon size="28"
                            color="secondary"
                            class="mt-2"
                            @click="showFilters = !showFilters">{{ buttonIcon }}</v-icon>
                </v-col>
                </v-row>
            <v-row v-if="showFilters">
                <v-col cols="12" md="3" class="py-0" v-if="selectedType.value !== 'places'">
                    <Autocomplete :label="$t('navigation.employees_groups')"
                                  :items="filterableEmployeesGroups"
                                  name="employeesGroups"
                                  @change="getData()"
                                  ref="employeesGroups"
                                  :loading="isEmployeesGroupsLoading"
                                  :search-input.sync="searchEmployeesGroups"
                                  @focus="getEmployeesGroups"
                                  @load-more-items="getEmployeesGroupsLazyLoading"
                                  v-model="employeesGroups"
                    ></Autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                    <Autocomplete :label="$t('labels.object_groups')"
                                  name="placeGroups"
                                  @change="getData()"
                                  :items="siteGroups"
                                  v-model="placeGroup"></Autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="py-0" v-if="selectedType.value === 'employee_groups'">
                    <Autocomplete :label="$t('labels.incident_type')"
                                  name="incidentTypeId"
                                  :items="filterableTypesList"
                                  @change="getData()"
                                  :loading="isTypesLoading"
                                  :search-input.sync="searchTypes"
                                  @load-more-items="getIncidentsTypesLazyLoading"
                                  v-model="incidentTypeId"></Autocomplete>
                </v-col>
            </v-row><v-row v-else><v-col cols="12" class="pt-7 pb-8"></v-col></v-row>
        </v-container>
        <v-container fluid>
            <Chart v-if="loaded"
                   :chartData="chartData"
                   :chartOptions="chartOptions"
                   :chartType="chartType"></Chart>
        </v-container>
    </section>
</template>

<script>
import Chart from '@/components/Chart';
import { mapState, mapGetters } from 'vuex';
import SelectField from '@/components/widgets/forms/SelectField';
import YearPicker from '@/components/widgets/forms/YearPicker';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import debouncedCompanies from '@/mixins/debounced/debouncedCompanies';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedPositions from '@/mixins/debounced/debouncedPositions';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import debouncedEmployeesGroups from '@/mixins/debounced/debouncedEmployeesGroups';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypes';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import { ICONS } from '@/constants/icons';

export default {
    name: 'IncidentsQuantity',
    components: { SelectField, YearPicker, Autocomplete, Chart },
    mixins: [
        debouncedCompanies,
        debouncedPositions,
        debouncedEmployees,
        debouncedLocations,
        debouncedEmployeesGroups,
        debouncedSites,
        debouncedIncidentTypes,
        debouncedCustomers
    ],
    data: () => ({
        ICONS,
        showFilters: false,
        selectedYear: new Date().getFullYear().toString(),
        employeesGroups: null,
        siteId: null,
        placeGroup: null,
        incidentTypeId: null,
        customerId: null,
        locationId: null,
        chartType: 'line',
        chartOptions: {
            scales: {
                y: {
                    stacked: true
                }
            },
            maintainAspectRatio: false,
            legend: {
                labels: {
                    boxWidth: 10
                },
                position: 'top'
            },
            animation: {
                duration: 1000,
                easing: 'easeInOutQuart'
            }
        },
        loaded: false,
        selectedType: { text: () => this.$t('navigation.incidents_types_new'), value: 'kinds' },
        options: {
            responsive: true,
            maintainAspectRatio: false
        }
    }),
    computed: {
        buttonIcon () {
            return this.showFilters ? ICONS.SINGLE_UP : ICONS.SINGLE_DOWN;
        },
        currentYear () {
            return new Date().getFullYear();
        },
        increase () {
            return this.cumulativeSum(this.sumArrays(...this.stats));
        },
        stats () {
            if (this.rawNumberOfIncidents) {
                if (this.rawNumberOfIncidents.by_employee_group_ids) {
                    return Object.values(this.rawNumberOfIncidents.by_employee_group_ids)
                        .map(e => Object.values(e.stats_per_month));
                } else if (this.rawNumberOfIncidents.by_kind_ids) {
                    return Object.values(this.rawNumberOfIncidents.by_kind_ids)
                        .map(e => Object.values(e.stats_per_month));
                } else if (this.rawNumberOfIncidents.by_place_ids) {
                    return Object.values(this.rawNumberOfIncidents.by_place_ids)
                        .map(e => Object.values(e.stats_per_month));
                } else return [];
            } else return [];
        },
        statsItemsDetails () {
            if (this.rawNumberOfIncidents) {
                if (this.rawNumberOfIncidents.by_employee_group_ids) {
                    return Object.values(this.rawNumberOfIncidents.by_employee_group_ids).map(e => {
                        return {
                            label: e.group_name,
                            data: Object.values(e.stats_per_month),
                            stack: 'combined',
                            type: 'bar',
                            backgroundColor: this.dynamicColors()
                        };
                    });
                } else if (this.rawNumberOfIncidents.by_kind_ids) {
                    return Object.values(this.rawNumberOfIncidents.by_kind_ids).map(e => {
                        return {
                            label: e.incident_kind_name,
                            data: Object.values(e.stats_per_month),
                            stack: 'combined',
                            type: 'bar',
                            backgroundColor: this.dynamicColors()

                        };
                    });
                } else if (this.rawNumberOfIncidents.by_place_ids) {
                    return Object.values(this.rawNumberOfIncidents.by_place_ids).map(e => {
                        return {
                            label: e.place_name,
                            data: Object.values(e.stats_per_month),
                            stack: 'combined',
                            type: 'bar',
                            backgroundColor: this.dynamicColors()

                        };
                    });
                } else return [];
            } else return [];
        },
        perOptions () {
            return [
                { text: this.$t('navigation.incidents_types_new'), value: 'kinds' },
                { text: this.$t('navigation.employees_groups'), value: 'employee_groups' },
                { text: this.$t('labels.sites'), value: 'places' }
            ];
        },
        labels () {
            return [
                this.$t('labels.months.jan'),
                this.$t('labels.months.feb'),
                this.$t('labels.months.mar'),
                this.$t('labels.months.apr'),
                this.$t('labels.months.may'),
                this.$t('labels.months.jun'),
                this.$t('labels.months.jul'),
                this.$t('labels.months.aug'),
                this.$t('labels.months.sep'),
                this.$t('labels.months.oct'),
                this.$t('labels.months.nov'),
                this.$t('labels.months.dec')
            ];
        },
        ...mapState('numberOfIncidents', [
            'rawNumberOfIncidents'
        ]),
        ...mapGetters('incidents', [
            'filterableTypesList'
        ]),
        ...mapState('locale', [
            'currentLocale'
        ]),
        ...mapGetters('sites', [
            'siteGroups'
        ]),
        ...mapGetters('customers', [
            'listingCustomers'
        ]),
        ...mapGetters('employeesGroups', [
            'filterableEmployeesGroups'
        ]),
        chartData () {
            return {
                labels: this.labels,
                datasets: [
                    // {
                    //     label: this.$t('navigation.incidents'),
                    //     data: this.sumArrays(...this.stats),
                    //     borderColor: 'rgb(255, 99, 132)',
                    //     backgroundColor: 'rgba(235, 76, 24, 0.5)',
                    //     stack: 'combined',
                    //     type: 'bar'
                    // },
                    {
                        label: this.$t('navigation.incidents_quantity'),
                        borderColor: 'rgb(54, 162, 235)',
                        backgroundColor: 'rgba(54, 162, 235, 0.0)',
                        data: this.increase,
                        stack: 'combined'
                    },
                    ...this.statsItemsDetails
                ]
            };
        }
    },
    methods: {
        dynamicColors () {
            const r = Math.floor(Math.random() * 255);
            const g = Math.floor(Math.random() * 255);
            const b = Math.floor(Math.random() * 255);
            return `rgb(${r},${g},${b})`;
        },
        reset () {
            this.employeesGroups = null;
            this.siteId = null;
            this.placeGroup = null;
            this.incidentTypeId = null;
            this.customerId = null;
            this.locationId = null;
            this.getData();
        },
        cumulativeSum (arr) {
            let csum = 0;
            if (arr) {
                return arr.map(function (x) {
                    return (csum += x);
                });
            } else return csum;
        },
        sumArrays (...arrays) {
            const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
            const result = Array.from({ length: n });
            return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0));
        },
        getData () {
            this.loaded = false;
            return this.$store.dispatch('numberOfIncidents/getNumberOfIncidents', {
                'locale': this.currentLocale,
                'year': this.selectedYear,
                'per': this.selectedType.value,
                'customer_id': this.customerId,
                'place_id': this.siteId,
                'point_id': this.locationId,
                'employee_group_id': this.employeesGroups,
                'place_group_id': this.placeGroup,
                'incident_kind_id': this.incidentTypeId
            }).then(() => {
                this.loaded = true;
            });
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.CUSTOM_REPORTS)) {
                vm.getData();
                vm.getIncidentsTypes();
                vm.getCustomers(null, null, true);
                vm.$store.dispatch('sites/getSiteGroups');
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>
